
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import * as Yup from "yup";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import GroupService from "@/core/services/GroupService";
import BaseRadio from "@/components/base/form/BaseRadio.vue";

export default defineComponent({
  name: "WorkspaceForm",
  components: {BaseRadio, FormErrorMessage, BaseForm, Field},
  props: {
    workspaceId: {type: String},
    workspace: {}
  },
  emits: ['saved'],
  setup(props) {
    const model = ref(props.workspace);
    watch(() => props.workspace, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    const validate = Yup.object().shape({
      name: Yup.string().required().label("Name"),
    });
    return {
      submitting,
      validate,
      model,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true
      if (this.workspaceId) {
        GroupService.update(this.workspaceId, this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false
        })
      } else {
        GroupService.create(this.model).then(res => {
          this.$emit('saved', res);
        }).finally(() => {
          this.submitting = false;
        })
      }
    }
  }
})


import {computed, defineComponent} from "vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import WorkspaceForm from "@/views/workspace/WorkspaceForm.vue";
import {useGroupStore} from "@/store/group.store";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import PrivacyName from "@/views/workspace/PrivacyName.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "Workspaces",
  components: {HelpEmbed, InfoBox, PrivacyName, WorkspaceForm, BaseModal},
  setup() {
    const groupStore = useGroupStore();
    const group = computed<any>(() => groupStore.entity)
    return {
      groupStore,
      group
    }
  },
  methods: {
    onAdd() {
      const modal = this.$refs.formWorkspaceRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.formWorkspaceRef as typeof BaseModal;
      modal.hideBaseModal();
      this.groupStore.get(this.group.id);
      store.dispatch(Actions.LOAD_PROFILE)
    }
  }
})


import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "PrivacyName",
  props: {
    privacy: {}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const buildLabel = (code) => {
      switch (code) {
        case 'PRIVATE':
          data.value = {name: 'Private', clazz: 'secondary'}
          break
        case 'PUBLIC':
          data.value = {name: 'Public', clazz: 'info'}
          break
      }
    }
    buildLabel(props.privacy);
    watch(() => props.privacy, (cb) => {
      buildLabel(cb);
    })
    return {
      data
    }
  }
})
